<script setup lang="ts">
import { type ISbStoryData } from '@storyblok/js';
import { CACHE_KEY_TAGS } from 'configs';
import {
  getDurationInSeconds,
  Logger,
} from 'utils';
import { getErrorMessage } from '@/config/errors';
import { getSlugFromParams } from '@/utils/urlHandling';
import type { PageStoryblok } from '@/storyblok-types';

const GqlInstance = useGql();

const route = useRoute('slug___uk');

const slug = getSlugFromParams(route.params.slug) || '/';

const { locale } = useI18n();

const {
  getStoryblokVersion,
  getStoryHeadData,
} = useStoryblokData();

const { getPageHead } = usePageHead();

const story = ref<ISbStoryData<PageStoryblok> | null>();

const { data } = await useCachedAsyncData(
  `story-${slug}-${locale.value}-${getStoryblokVersion.value}`,
  async () => {
    try {
      return await GqlInstance('StoryblokStory', {
        localeCode: locale.value,
        slug,
        version: getStoryblokVersion.value,
      });
    } catch (error) {
      Logger.error(`Could not fetch story for slug: "${slug}"`, error);
      return null;
    }
  },
  {
    clientMaxAge: getDurationInSeconds({ minutes: 15 }),
    serverCacheTags: [ CACHE_KEY_TAGS.NUXT_MULTI_CACHE_DEFAULT ],
    serverMaxAge: getDurationInSeconds({ minutes: 15 }),
  },
);

story.value = data.value?.singleStory?.story;

if (!story.value) {
  throw createError({ ...getErrorMessage('story', `Story not found on slug: "${slug}"`) });
}

const { initCommunicationBar } = useCommunicationBar();

const designListingBlock = story.value?.content.body?.find((item) => item.component === 'DesignListing');

if (designListingBlock) {
  let productFilter = [];
  const pageProductFilter = designListingBlock.algoliaFilterProduct;

  productFilter = designListingBlock.algoliaFilterProduct
    ? (pageProductFilter as string[])
    : [];

  const productQueryParams = route.query.product as string[] | string;

  if (productQueryParams) {
    productFilter = Array.isArray(productQueryParams)
      ? productQueryParams
      : [ productQueryParams ];
  }

  await initCommunicationBar({
    page: 'listing-page',
    productFilter,
  });
}

useHead(getPageHead(getStoryHeadData(story.value)));

provide('parentId', story.value.parent_id);

onMounted(() => {
  if (!story.value) {
    return;
  }
  useStoryblokBridge(
    story.value?.id,
    (evStory) => {
      story.value = evStory;
    },
    {
      resolveLinks: 'url',
      resolveRelations: [ 'Article.author' ],
    },
  );
});
</script>

<template>
  <div
    v-if="story"
    v-editable="story.content"
    class="z-10"
  >
    <StoryblokComponent
      v-for="item in story.content.body"
      :key="item._uid"
      :blok="item"
    />
  </div>
</template>
